






import { Component, Vue } from 'vue-property-decorator';
@Component({
  components: {
    InsCopyright: () => import('@/components/business/footer/InsCopyright.vue')
  }
})
export default class RNPLayout extends Vue {

}
