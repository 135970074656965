export default {
  Message: {
    Logout: '您尚未登陸或已登錄超時，請重新登陸',
    Message: '消息',
    Confirm: '確認',
    Cancel: '關閉',
    msg401: '權限不足！',
    msg400: '系統異常！',
    msg404: '請求接口不存在！',
    msg500: '服務器錯誤！',
    msg502: '网络错误！',
    msg503: '服務不可用，服務器暫時過載或維護。',
    SucceedInOperating: '操作成功',
    SavedSuccessfully: '保存成功',
    Loading: '頁面正在加載，請稍候',
    ViewDetail: '瞭解更多',
    FollowUs: '關注我們',
    Evidence: '憑據',
    UploadEvidence: '上傳憑據',
    OrderNumber: '訂單編號',
    Remark: '備註',
    Image: '圖片',
    SubmitSuccess: '提交成功',
    SubmitFailed: '提交失敗',
    DeliveryDate: '送貨日期',
    DeliveryTime: '送貨時段',
    DeliveryInstructions: '送貨指示',
    DeliveryMark: '預訂請預早⼀天，每天晚上10 點截單，套餐滿800 包市區送貨。10點前⼊數⽅可隔天送貨。',
    All: '全部',
    PlsAddPiUpAddr: '請添加相關自提地址信息！',
    NoneAddPiUpAddr: '查詢不到相關自提地址信息！',
    AdditionalDiscount: '額外折扣已生效',
    Delete: '刪除',
    SuccessfullyDeleted: '刪除成功',
    Save: '保存',
    SaveAll: '保存所有',
    noRMBStripe: '貨幣RMB不支持Stripe支付，請選擇其他支付方式或切換貨幣模式',
    textareaError: '請輸入5個字符以上',
    FindNoProduct: '系統正忙，請重新刷新頁面',
    Readmore: '閱讀更多',
    Shopnow: '立即購物',
    InternationalCertification: '國際認證',
    CONTACTUS: '聯絡我們',
    PaymentMethods: '支付方式',
    ElderlyCareInformation: '長者護理資訊',
    Wholesaleinquiry: '批發查詢',
    Pointofsale: '搜索銷售點',
    SubscribeTips: '訂閱安心寶通訊電郵及成為會員，獲取獨家優惠、最新推出的長者商品、和長者護理的資訊。',
    SubscribeBtn: '訂閱獲取獨家優惠',
    checkoutTipsA: '購物滿 HK$600.00以上免运费 (注意 : 赤柱 / 東涌 / 離島 / 西貢或偏遠地區運費附加費需要另議)',
    checkoutTipsB: '- 送貨上樓梯需加收費用',
    checkoutTipsC: '- 選擇自行提取貨物的客人必須提前三天致電2417 3236預約 (只收現金)',
    keywords: '請輸入關鍵字',
    Exploremore: '探索更多',
    Discovermore: '發現更多',
    More: '查看更多',
    nomessage: '暫無內容',
    Apply: '報名'
  },
  DeliveryAddress: {
    AddAddress: '新增地址',
    ChooseDeliveryAddress: '選擇送貨地址',
    EditBtn: '编辑',
    DeleteBtn: '删除',
    AddDeliveryAddress: '添加新的收貨地址',
    PostalCode: '郵政編碼',
    Area: '地區',
    Province: '省份',
    District: '區份',
    Address: '地址',
    DefaultAddress: '設為默認送貨地址?',
    SaveBtn: '保存',
    ResetBtn: '重置',
    CloseBtn: '關閉',
    YesBtn: '是',
    NoBtn: '否',
    AddFirstName: '名字',
    AddLastName: '姓氏',
    UserContactNumber: '聯繫電話',
    Mobile: '手提電話',
    Addressprovince: '請選擇省份',
    AddressArea: '請選擇地區',
    Detail: '街道/屋苑/樓層/室'
  },
  Order: {
    OrderNumber: '訂單編號',
    OrderDateTime: '訂單日期&時間 ',
    TotalPrice: '總價',
    OrderStatus: '訂單狀態',
    Paid: '已付款',
    Unpaid: '待付款',
    PayNow: '立即支付'
  },
  RegNPay: {
    BackText: '返回',
    UploadButtonText: '上傳文件(jpg, png, pdf)',
    UploadingText: '正在上傳',
    ConfirmText: '確定',
    UploadSuccessfulText: '上傳成功',
    UploadFailText: '上傳失敗',
    NoFileText: '沒有選中文件',
    UploadLengthText: '最多只能上載{0}檔案',
    UploadSizeText: '最多只能上傳{0}M',
    PleaseSelect: '請選擇',
    SubRecord: '提交紀錄',
    ReferenceNum: '參考編號',
    SubmissionDate: '提交日期',
    Print: '列印',
    PlsPayWay: '請選擇付款方式',
    PreviewTitleText: '請再次確認頁面',
    FormatErrorText: '請輸入正確電郵地址',
    RequiredText: '請檢查你的答案'
  },
  Display: {
    SuccessCase: '成功個案',
    GeneralQuery: '壹般查詢',
    OfficeHours: '辦公時間',
    Hour0: '星期壹至五：',
    Hour1: '早上9時至下午12時,',
    Hour2: '下午1時30分至晚上6時',
    Hour3: '星期六、日及公眾期假休息',
    AddrInfo: '長沙灣荔枝角道 777號田氏企業中心 3樓 306室 (長沙灣港鐵站 B出口)'
  },
  Action: {
    ViewMore: '查看更多',
    SignUp: '立即報名',
    ReturnHomePage: '返回首頁',
    OrderDetails: '訂單詳情',
    ViewDetail: '瞭解更多'
  },
  Cms: {
    PlsChoose: '請點擊選擇您想要查看的內容：'
  },
  payment: {
    PaymentSuccessTips: '支付結果： 成功',
    PaymentFailTips: '支付結果： 失敗'
  },
  Calendar: {
    KnowMore: '了解更多',
    Time: '時間',
    Venue: '地點',
    NoEvent: '今日暫無活動'
  },
  photo: {
    photo: '相片',
    BackText: '返回上一級'
  },
  pager: {
    Last: '上一頁',
    Next: '下一頁',
    per: '第',
    page: '頁',
    Total: '共'
  },
  Home: {
    Home: '首頁',
    Search: '搜索結果',
    ReadMore: '更多',
    PersonalInformation: '個人信息',
    BecomeMember: '成為會員',
    ContactUs: '聯絡我們'
  },
  Login: {
    doLogin: '登入',
    LoginTitle: '會員登入',
    NewUser: '新用戶',
    RegNow: '現在注冊',
    ForgetPwd: '忘記密碼',
    RememberMe: '記住我',
    FaceBookUse: '使用facebook賬戶登錄',
    UserName: '登錄用戶名/郵箱地址',
    EnterEmail: '請輸入郵箱地址',
    CorrectEmail: '請輸入正確郵箱地址',
    LoginPwd: '請輸入密碼',
    LoginoldPwd: '密碼不正確',
    Loginerror: '賬號或密碼不正確',
    LoginSucceeded: '登入成功！',
    Account: '個人中心',
    MemberLogin: '會員登入'
  },
  Register: {
    RegisterTitle: '註冊會員',
    UserEmail: '郵箱',
    or: '或',
    UserRegPassword: '密碼',
    UserNewPassword: '新密碼',
    UserOldPassword: '舊密碼',
    UserRegTips: '最少6個由字母或數字組成的字符',
    UserConfirmPassword: '確認密碼',
    UserFirstName: '名字',
    UserLastName: '姓氏',
    UserContactNumber: '聯繫電話',
    UserBirthDate: '出生日期',
    UserGender: '性別',
    UserMale: '男',
    UserFemale: '女',
    UserLanguage: '語言',
    UserLanguageE: '英文',
    UserLanguageT: '繁體',
    UserLanguageS: '簡體',
    RegisterBtn: '註冊',
    RegisterAgree: '我同意確認以上所提供的資料正確無誤',
    RegisterEmail: '郵箱不能為空',
    RegisterEmail01: '請輸入正式的郵箱格式',
    RegisterPassword: '請輸入密碼',
    RegisterNewPassword: '請再次輸入密碼',
    RegisterNewPassword01: '兩次輸入密碼不一致',
    RegisterFirstName: '請輸入您的姓名',
    RegisterLastName: '請輸入您的名稱',
    RegisterAgree_check: '請勾選',
    RegisterMobile: '請輸入您的手提電話',
    RegisterSuccess: '註冊成功',
    RegisterFail: '註冊失敗',
    RegisterWelcome: '恭喜您,註冊成功',
    RegisterTips: '此電子郵箱尚未註冊，請立即註冊成為會員！',
    Login: '跳轉登錄',
    Registerdigital: '長度由8至20個字符 須包含大寫字母、小寫字母及數字'
  },
  Notification: {
    MyMessages: '我的信箱',
    MsgList: '消息列表',
    TotalRecQtyWithSymbol: '總記錄數 ：',
    UnreadWithSymbol: '未讀記錄數 ：',
    MsgUnreadOnly: '只看未讀消息',
    MsgMarkAsRead: '標記為已讀',
    CheckAllOrNot: '全選/反選',
    MsgSender: '發信人',
    MsgSubject: '主題',
    MsgSenderDate: '發信時間',
    MsgReadStatus: '狀態',
    Details: '瞭解詳情',
    More: '更多',
    Back: '返回',
    MsgContent: '内容',
    Send: '發送',
    Unread: '未讀',
    Read: '已讀',
    UpdateSucceeded: '更新成功!',
    Check: '選擇',
    Id: '序號'
  },
  Forgetpassword: {
    ForgetPassword: '忘記密碼',
    ResetPwdTips: '請輸入您用於註冊的電子郵件，然後點擊“下一步”繼續',
    LoginNameEmail: '郵箱',
    NextStep: '下一步',
    ResetPwdSuccess: '重置密碼已發送到您的郵箱，請查看。',
    NotEmail: '收不到重設密碼電郵',
    ToReEnter: '按此重新填寫',
    EnterEmail: '請輸入郵箱地址',
    CorrectEmail: '請輸入正確的郵箱地址',
    ResetPwd: '重設密碼'
  },
  MemberInfo: {
    MemberInfoTitle: '用戶信息',
    UserAccount: '用戶賬號',
    ModifyPassword: '修改密碼',
    EnterUserName: '請輸入名字',
    EnterUserLastName: '請輸入姓氏',
    EnterUserPhone: '請輸入聯係電話',
    EnterUserBrithDate: '請選擇出生日期',
    EnterOldPwd: '請輸入密碼',
    EnterNewPwd: '請輸入新密碼',
    EnterComfirmPwd: '請輸入確認密碼',
    EnterNumber: '請輸入數字',
    PwdNotCorrect: '兩次密碼不一致',
    AgreeEmail: '本人同意天才兒童書店透過電郵地址向我發送推廣資訊',
    DDMM: '日/月'
  },
  Input: {
    email: '請輸入正確的郵箱',
    number: '請輸入數字',
    input: '請輸入',
    text: '',
    date: '請選擇日期',
    password: '兩次輸入密碼不一致',
    textarea: '',
    emailNotFound: '郵箱不存在',
    logopassword: '請輸入密碼',
    confirmpassword: '兩次輸入密碼不一致',
    nomessage: '暫無數據',
    phoneincorrect: '電話號碼格式不正確'
  },
  MessageNew: {
    Search: '搜尋',
    Title: '標題',
    Date: '日期',
    More: '更多',
    President: '會長和專業組別主席的話',
    HIREA: '大灣區紅鳥企業家聯盟',
    Event: '活動',
    News: '最新消息',
    Disclaimer: '免責聲明',
    CPD: '持續專業發展活動',
    ReadMore: '閱讀更多',
    NoContent: '暂無內容',
    Year: '年份',
    Month: '月份',
    All: '所有',
    Hirea: '大灣區紅鳥企業家聯盟',
    selectYear: '請選擇年份',
    UpdateTime: '更新日期: 2022年06月01日',
    LearnMore: '了解更多'
  },
  Account: {
    MemberCenter: '會員中心',
    MemberInformation: '用戶信息',
    MyMessages: '我的信箱',
    MyFavorite: '我的最愛',
    MyOrder: '我的訂單',
    DeliveryAddress: '送貨地址',
    Logout: '登出',
    Welcome: '歡迎!',
    SISA: '選擇成功',
    Time: '時間'
  },
  Month: {
    January: '一月',
    February: '二月',
    March: '三月',
    April: '四月',
    May: '五月',
    June: '六月',
    July: '七月',
    August: '八月',
    September: '九月',
    October: '十月',
    November: '十一月',
    December: '十二月'
  },
  MemberLogin: {
    MemberLogin: "Member's Login",
    MyPersonalInformation: '我的個人資料',
    MyCPDRecords: '我的持續專業進修活動記錄',
    MyApplications: '我的申請及付款紀錄',
    MyMailBox: '我的郵箱',
    Update: '更新',
    Back: '返回',
    ModifyPassword: '修改密碼'
  },
  MyPersonalInformation: {
    Welcome: '歡迎',
    LastName: '英文姓氏',
    FirstName: '英文名字',
    ChineseName: '中文姓名',
    ClassofMembership: '會員類別',
    MembershipNumber: '會員編號',
    Divisions: '專業組別',
    ContactAddress: '通信地址',
    CurrentCompanyNameandAddress: '現職公司名稱及地址',
    OfficeTelNo: '公司電話',
    CompanyEmail: '公司電郵地址',
    Fax: '傳真',
    HomeAddress: '住宅地址',
    HomeTelNo: '住宅電話',
    PersonalEmail: '個人電郵地址',
    MobileNoHK: '流動電話(香港) ',
    MainContactNo: '其他聯絡電話號碼',
    receiveInfo: '我想經以下途徑收取香港地產行政師學會資訊：',
    Post: '郵件',
    Email: '電郵',
    SMSMessage: 'SMS短訊 '
  },
  MyCPDRecords: {
    UpcomingCPDActivities: '即將舉行的活動',
    CPDActivitiesRecord: '活動記錄',
    ActivityDate: '日期',
    ActivityTitle: '活動名稱',
    Category: '類別',
    Subject: '科目',
    ActivityMode: '活動模式',
    CPDPointsAccredited: '所獲學分',
    MediumofInstruction: '授課語言',
    Certificate: '登記',
    Register: '註冊'
  },
  MyApplicationsPaymentRecords: {
    MyApplicationsPaymentRecords: '我的申請及付款紀錄',
    Underreview: '審核中（請在三天內提交您的支票）',
    ActivityTitle: '活動項目',
    Referenceno: '交易參考編號',
    Date: '日期',
    Total: '總數(HK$)',
    Membershipexpireson: '會員資格到期',
    Membershiprenewal: '會員续费',
    Status: '狀態'
  },
  MembersList: {
    MembersList: '會員名錄',
    SearchMember: '搜索會員',
    TipsA: '請在框中輸入要搜索的名稱',
    TipsB: '請填寫以下信息',
    Submit: '提交',
    MembershipNumber: '會員編號',
    NameinEnglish: '英文姓名',
    NameinChinese: '中文姓名',
    Professionaltitle: '專業稱號',
    ClassofMembership: '會員類別'
  },
  MembershipApplicationForm: {
    MembershipApplicationForm: '會籍申請表格',
    ClassofMembershipApply: '申請會員類別',
    MembershipNumber: '會員編號',
    StudentMember: '學生會員',
    AssociateMember: '聯繫會員 (AHIREA)',
    GraduateMember: '畢業生會員',
    ProfessionalMember: '專業會員 (MHIREA)',
    Divisions: '專業組別',
    MaychoosemorethanONEdivision: '請選取期望加入的專業組別',
    PropertyDevelopment: '地產發展及策劃',
    LegalPractice: '法律執業及調解',
    ConstructionDesign: '建築及保育工程',
    CorporateMgtFinance: '企業融資及資產管理',
    PropertyManagement: '物業及設施管理',
    SalesLeasing: '房地產租售及推廣',
    RealEstateTechnology: '建設科技及創新',
    Academic: '學術及教育培訓',
    Important: '申請人填寫及呈交此表格前，須詳細閱讀及遵照本會入會申請辦法及規條處理。',
    FamilyName: '姓 (教授/博士/先生/女士)',
    OtherNames: '名',
    NameinChinese: '中文姓名',
    Nationality: '國籍',
    DateofBirth: '出生日期 (DD / MM / YYYY)',
    HKIDCard: '香港身份証 護照號碼',
    CurrentAddress: '現職公司名稱及地址',
    OfficeTelNo: '公司電話',
    CompanyEmail: '公司電郵地址',
    HomeAddress: '住宅地址',
    HomeTelNo: '住宅電話',
    PersonalEmail: '個人電郵地址',
    MobileNo: '流動電話 (香港)',
    MainContactNo: '主要聯絡電話號碼',
    Usemailingaddress: '請以 *公司/住宅 地址作為郵寄地址',
    AcademicQualifications: '學術資歷',
    Pleaseprovidecopy: '請提供副本',
    Forapplying: '如申請「物業管理組」會員，申請人需要提交符合《物業管理業監管局》，物業管理人(第 1 級)或(第 2 級)牌照之學歷要求相關資料。',
    NameofUniversity: '大學/教育機構名稱',
    QualificationAchieved: '考獲資格 (學位/文憑)',
    Yearattended: '就讀年份',
    From: '由 (MM/YY)',
    To: '至 (MM/YY)',
    Remarks: '備註',
    spaceisnotsufficient: '如空間不敷應用，請使用本表格之補充資料頁 A，以進一步補充相關資料。',
    StatutoryProfessionalQualifications: '法定專業資歷',
    NameofProfessionalInstitution: '專業團體名稱',
    LicenceGrade: '牌照、會員資歷 (級別/組別)',
    DateObtained: '考獲日期 (DD/MM/YY)',
    PropertyManagementServicesAuthority: '物業管理業監管局',
    PMPTier1: '物業管理人 (第 1 級)',
    PMPTier2: '物業管理人 (第 2 級)',
    EstateAgentsAuthority: '地產代理監管局',
    EstateAgent: '地產代理 (個人)',
    EstateSalesperson: '地產營業員',
    ArchitectsRegistrationBoard: '建築師註冊管理局',
    RegisteredArchitect: '註冊建築師',
    EngineersRegistrationBoard: '工程師註冊管理局',
    RegisteredProfessionalEngineer: '註冊專業工程師',
    SurveyorsRegistrationBoard: '測量師註冊管理局',
    RegisteredProfessionalSurveyor: '註冊專業測量師',
    PlannersRegistrationBoard: '規劃師註冊管理局',
    RegisteredProfessionalPlanner: '註冊專業規劃師',
    LandscapeArchitectsRegistrationBoard: '園境建築師註冊管理局',
    RegisteredLandscapeArchitect: '註冊園境建築師',
    HousingManagerRegistrationBoard: '房屋經理註冊管理局',
    RegisteredProfessionalHousingManager: '註冊專業房屋經理',
    OtherProfessionalQualifications: '其他專業資歷',
    CurrentEmploymentDetails: '現職工作詳情',
    personalCV: '請提供詳細個人履歷',
    CompanyNameCurrent: '公司名稱 (現職)',
    CurrentPosition: '現時職位',
    CurrentPositionSince: '現職開始時間 (MM/YY)',
    NatureofDuties: '現時職務範圍',
    Pleasetick: '請在適當方格加上剔號',
    SizeofCompany: '公司規模',
    ListedCompany: '上市公司 / 直屬員工超過 100 人以上',
    CompanywithA: '公司直屬員工有 51-100 人',
    CompanywithB: '公司直屬員工有 31-50 人',
    CompanywithC: '公司直屬員工有 10-30 人',
    CompanywithD: '公司直屬員工少於 10 人',
    FounderExecutiveDirector: '創辦人 / 執行董事 / 合伙人 / 主席',
    DirectorGeneralManager: '董事 / 總經理 / 首席執行官 / 總幹事',
    SeniorManager: '高級經理 / 專業經理',
    ManagerDeputyManager: '經理 / 副經理 / 助理經理',
    Others: '其他',
    Pleaseshowyourposition: '請以組織架構圖表示閣下現時工作的職位及直接管轄員工數目',
    UploadFile: '上傳',
    Currenttotalnumber: '現時直接管轄員工總數',
    ScopeofWork: '工作範圍',
    ScopeofWorkA: '% 地產行政',
    ScopeofWorkB: '% 其他',
    PastEmploymentHistory: '過往工作經驗',
    NameofCompany: '公司名稱',
    Position: '職位',
    Period: '期間',
    NatureofDutiesA: '職務範圍',
    SizeofCompanyA: '公司規模 (職員總數)',
    SheetB: '如空間不敷應用，請使用本表格之補充資料頁 B，以進一步補充相關資料。',
    CurrentandPrevious: '現時及過往公職',
    Particulars: '詳情',
    CriminalConvictionRecord: '刑事定罪紀錄',
    CriminalConvictionRecordTipsA: '你有否曾因任何刑事罪行在香港或其他地方被定罪?',
    CriminalConvictionRecordTipsB: '你曾否被判處監禁 (包括緩刑) ?',
    CriminalConvictionRecordTipsC: '你曾否面對任何紀律處分 (包括專業團體、地產代理監管局及物業管理業監管局) ?',
    Yes: '有',
    No: '沒有',
    I: '本人',
    CriminalConvictionRecordRemark: '就上述提問，如你的答案是「有」，請另加紙張提供有關的資料或解釋，包括有關定罪日期、罪名、刑罰及審訊該案的法院名稱等。',
    wishtojoinA: '欲申請加入“香港地產行政師學會”成為會員。 本人明白 貴學會將對本人在申請表上所填寫之個人資料保密，但會將有關',
    wishtojoinB: '資料提供予貴學會委員會之成員，在會籍審批過程中使用及隨時查閱。 本人同意 貴學會對本人資料的查閱．並明白此與《個人資料（私穩）條例》並無抵觸。茲聲明就本人所言所信，在此表格內所填報之資料均屬確實無訛，本人同意遵守所有會規包括經會方在任何時間通過更改之一切會規內容。 本人亦同意此申請表將由 貴學會保存記錄直至本人以書面通知 貴學會撤回申請會籍為止。',
    Iencloseherewith: '茲附上支票號碼',
    HK$: '港幣',
    beingtheassessmentA: '作為審核費用 (港幣 250 元) 及首年年費之用．如本人申請不被接納，會方將',
    beingtheassessmentB: '退回該款項(審核費用除外)予本人。',
    SignatureofApplicant: '申請人簽署',
    Date: '日期',
    Proposer: '提名人',
    MustbeaFellow: '必須為資深或專業會員',
    NameofProposer: '提名人姓名',
    ClassofMembership: '會員類別',
    MembershipNo: '會員編號',
    Signature: '簽署',
    FOROFFICEUSEONLY: '本會專用',
    ApplicationReceivedon: '接獲申請日期',
    Acknowledgmentsent: '通知申請人接獲其會籍申請',
    Applicationvetted: '會籍事務小組審查申請',
    ApprovalRejection: '* 執行委員會 接納 / 反對 為： 專業會員/ 專業會員(物業管理組) /聯繫會員/ 聯繫會員(物業管理組) /畢業生會員/學生會員/境外會員',
    ApplicationResult: '會籍申請結果通知予申請人',
    NotesforMembershipApplication: '入會申請須知',
    ClassesofMembership: '會員分類及基本資格',
    TheInstitutehasstrictrequirements: '本會招收會員，務求嚴謹，以符合本會會綱及政府之要求。若有意成為香港地產行政師學會之會員，申請人原則上需符合有關會員類別之入會要求，如下表所列。唯執行委員會可就個別申請人的資歷，豁免其在相應會員類別上的具體要求。',
    BasicRequirements: '基本資格',
    HonoraryFellow: '名譽資深會員',
    FellowMember: '資深會員',
    ProfessionalMemberMHIREA: '專業會員',
    ProfessionalMemberPropertyManagementDivision: '專業會員 (物業及設施管理組) ',
    AssociateMemberAHIREA: '聯繫會員',
    AssociateMemberPropertyManagementDivision: '聯繫會員 (物業及設施管理組)',
    NonResidentMember: '境外會員',
    SeniorMemberFHIREA: '終身會員',
    BasicRequirementsTipsA: '執行委員推舉任可對本會或房地產業有特殊貢獻及服務之社會優秀或著名人士',
    BasicRequirementsTipsB1: '執行委員推舉',
    BasicRequirementsTipsB2: '持有香港永久居民身份證明',
    BasicRequirementsTipsC1: '任何年齡在 25 歲以上的行政人員，持有本會執行委員會認可或經香港職業及學術資歷評審局認證的房地產相關學位或文憑(香港資歷架構第 5 級或以上)或已通過本會考評，並擁有至少 5 年地產業經理級相關工作經驗；或',
    BasicRequirementsTipsC2: '任何本會已認可的其他專業學會正式成員，並具備成為其他專業學會正式成員後 2 年以上地產業經理級相關工作經驗的人士；或',
    BasicRequirementsTipsC3: '非學位持有人，擁有超過 10 年的地產業主管級監督或管理經驗。',
    BasicRequirementsTipsC4: '專業會員必須是香港永久性居民身份證持有人。',
    BasicRequirementsTipsC5: '與“專業會員”的基本資格相同，及需符合《物業管理業監管局》物業管理人(第 1 級) 牌照之學歷及工作經驗要求。',
    BasicRequirementsTipsD1: '任何年齡在 25 歲以上的行政人員，持有合乎本會專業會員相關學歷，並擁有至少 3 年的地產業主管級相關工作經驗，或',
    BasicRequirementsTipsD2: '任何本會已認可的其他專業學會正式成員，具備地產業主管級相關工作經驗的人士，或',
    BasicRequirementsTipsD3: '非學位持有人，擁有超過 6 年的地產業主管級監督或管理經驗。',
    BasicRequirementsTipsD4: '與“專業會員”的基本資格相同，及需符合《物業管理業監管局》物業管理人(第1級) 牌照之學歷、工作經驗及持續專業發展要求。',
    BasicRequirementsTipsE: '完成本會學生會員所就讀或獲本會認可的相關地產行政課程',
    BasicRequirementsTipsF1: '現就讀本會執行委員會認可或批准的地產行政課程',
    BasicRequirementsTipsF2: '本會執行委員會可自行決定將學生會籍延續至有關課程持續時間之後',
    BasicRequirementsTipsG1: '長註海外工作的資深或專業會員或',
    BasicRequirementsTipsG2: '具本會專業會員資歷，但未持有香港永久性居民身份證，經本會確認批准其非境內居住人士',
    BasicRequirementsTipsH: '現有會員年滿 65 歲及最少持有本會會籍 10 年，經學會批准，可轉為終身會員',
    MembershipApplicationProcedures: '入會申請辦法及規條',
    Anylocalpractitioners: '本會歡迎所有在香港執業並符合本會專業會員或其他會員類別(包括聯繫會員)基本資格、聲譽及品德良好之地產行政人員加入本會，有意加入本會成為正式會員者，可照下列辦法及規條申請會籍：',
    MTA: '詳細正確填妥會籍申請表格（可於本會官方網站 http://www. hirea.org.hk 下載），並徵得本會一位資深會員或專業會員簽署提名，連同下述文件送呈本會（香港郵政總局信箱 4126 號）會籍事務小組主委處理。',
    MTB: '所有呈交之會籍申請表格，須附下列文件方為有效：',
    MTB1: '申請人之身份証或護照影印副本。',
    MTB2: '申請人之二吋乘一吋半彩色正面証件相片乙張。【註：請於相片背後寫上申請人之姓名以便識別。為使記錄完好，請勿使用「即影即有」相片。】',
    MTB3: '申請人填報之學歷、專業資格及從業記錄之證明文件影印副本全套。',
    MTB4: '詳細從業紀錄及現職之名片一張。',
    MTB5: '持續專業發展記錄表（若有）',
    MTB6: '支票乙張，抬頭寫「香港地產行政師學會」，銀碼為審核費用(港幣 250 元)及所申請有關會籍之一年會費。【註：如申請被接納者，所付支票將作清付申請人之審核費及首年會費費用。如申請暫不被接納者，將退還所申請有關會籍之一年會費給申請人。各類會籍之年費不同，按年調整，有關確實數目，可在本會網頁內查閱。】',
    MTC: '所有「會籍申請」皆先由本會執行委員會下之會籍事務小組詳細及嚴謹審評。如有需要，該組人員將直接聯絡申請者，要求澄清疑問及提供資料。',
    MTD: '會籍事務小組完成審核有關「會籍申請」後，將提交本會執行委員會覆核及正式表決。所有「會籍申請」之接納表決，需由執行委員會全體出席委員一致贊成通過方為有效。有關「會籍申請」之結果將隨即由會籍事務小組主委直接發覆函給申請人。',
    MTF: '本會保留接納或拒絕個別「會籍申請」個案而不作任何解釋之最終權，所有申請人須尊重及服從本會執行委員會之最後決定，不得異議。',
    MTG: '如有任何疑問或查詢，歡迎有意申請者與本會秘書處聯絡。',
    RemoveRow: '刪除行',
    AddRow: '添加行',
    Submit: '提交',
    Sign: '簽署',
    Save: '保存',
    Clear: '清空',
    Preview: '預覽',
    Modify: '修改',
    exceed: '超出數量',
    exceedText: '請先刪除原有文件，再重新選擇文件，因為超出1個文件限制。',
    MaxSize: '文件大小不能超過',
    Secretariat: '秘書處',
    TextMessageOnly: '只限文字訊息',
    Tel: '電話',
    TelContent: '3588 1799    9144 2633',
    Fax: '傳真',
    FaxContent: '3007 0675    2280 6582',
    Address: '地址',
    AddressContent: '香港郵政總局信箱 4126 號',
    Email: '電郵',
    EmailCotent: 'info@hirea.org.hk ',
    Website: '網址',
    WebsiteContent: 'https://cmx4-redbird.uat2.intimex.hk ',
    ParticularofEntrepreneur: '企業家詳情',
    EntrepreneurName: '企業家名',
    Surname: '姓',
    FirstName: '名字',
    HKUSTS: '香港科技大學附屬機構',
    multiple: '（可多選）',
    Department: '部門',
    affiliation: '入職時間',
    Upload: '上傳證明',
    ContactMeans: '聯繫方式',
    EmailAddress: '郵箱地址',
    WeChatID: '微信號（選填）',
    WhatSapp: 'WhatSapp號（選填）',
    Post: '職位',
    Particular: '公司詳細信息',
    CompanyName: '公司名稱',
    EnglishName: '英文名',
    ChineseName: '中文名（選填）',
    CompanyAddress: '公司地址',
    CompanyWeb: '公司網站',
    NatureBusiness: '經營範圍（可多選）',
    CompanyBackground: '公司背景',
    BusinessActivities: '經營活動',
    Collection: '數據收集的目的',
    Limited: '本表格中收集的個人數據將用於聯盟的會員服務，包括但不限於：'
  },
  BoAForm: {
    MaxSize: '文件大小不能超過',
    Required: '必填',
    Privacy: '私隱政策',
    Terms: '條件與條款',
    Disclaimer: '免責聲明',
    Last: '上一頁',
    Next: '下一頁',
    SelectDate: '選擇日期',
    SubmitSuccess: '提交成功',
    SubmitFail: '提交失敗',
    CorrectEmail: '請輸入正確的電郵',
    CorrectPhone: '請輸入正確的電話',
    Code: '代碼',
    Identity: '身份',
    ProjectSection: '項目部分',
    ProjectOwner: '項目擁有者',
    exceed: '超出數量',
    exceedText: '請先刪除原有文件，再重新選擇文件，因為超出1個文件限制。',
    RegSuccessTipsA: '我們正在審核您關於會員帳戶激活的申请',
    RegSuccessTipsB: '並很快會以電子郵件回覆您',
    ApplySuccess: '您已成功提交閣下的會員資料',
    Savedsuccessfully: '保存成功',
    Expired: '已過期',
    selectProposers: '選擇提名人'
  }
};
