export default {
  Message: {
    Logout: 'Timeout ! login now ?',
    Message: 'Message',
    Confirm: 'Ok',
    Cancel: 'Close',
    msg401: 'Insufficient authority!',
    msg400: 'System abnormal!',
    msg404: 'Request interface does not exist!',
    msg500: 'Server error!',
    msg502: 'Network error!',
    msg503: 'The service is not available, the server is temporarily overloaded or maintained.',
    SucceedInOperating: 'Succeed in Operating',
    SavedSuccessfully: 'Saved successfully',
    Loading: 'Page Loading, Please Wait',
    ViewDetail: 'VIEW DETAILS',
    FollowUs: 'Follow Us',
    Evidence: 'Evidence',
    UploadEvidence: 'Upload Evidence',
    OrderNumber: 'Order Number',
    Remark: 'Remark',
    Image: 'Image',
    SubmitSuccess: 'Submitted Successfully',
    SubmitFailed: 'Submit Failed',
    DeliveryDate: 'Delivery Date',
    DeliveryTime: 'Delivery Time',
    DeliveryInstructions: 'Delivery Instructions',
    DeliveryMark: 'Please make a reservation one day in advance. The order is cut off at 10 PM every day. The Package over 800 packages, urban delivery.Only Payment complete before 10PM can be delivered the next day',
    All: 'All',
    PlsAddPiUpAddr: 'Please add relevant self-referral address information ! ',
    NoneAddPiUpAddr: 'Unable to query the relevant address information!',
    AdditionalDiscount: 'The Additional Discount is In Effect',
    Delete: 'Delete',
    SuccessfullyDeleted: 'Successfully Deleted',
    Save: 'Save',
    SaveAll: 'SaveAll',
    noRMBStripe: 'Currency RMB does not support Stripe payment, please choose another payment method or switch currency mode',
    textareaError: 'Please enter more than 5 characters',
    FindNoProduct: 'The system is busy, please refresh the page again',
    Readmore: 'Read more',
    Shopnow: 'Shop now',
    InternationalCertification: 'International Certification',
    CONTACTUS: 'CONTACT US',
    PaymentMethods: 'Payment Methods',
    ElderlyCareInformation: 'Elderly Care Information',
    Wholesaleinquiry: 'Wholesale inquiry',
    Pointofsale: 'Point of sale ',
    SubscribeTips: 'Subscribe to the Anxinbao newsletter email and become a member to get exclusive offers, the latest products for the elderly, and information on elderly care. ',
    SubscribeBtn: 'Subscribe to get exclusive offers',
    checkoutTipsA: 'Free shipping on purchases over HK$600.00 (Note: The shipping surcharge for Stanley / Tung Chung / Outlying Islands / Sai Kung or remote areas needs to be negotiated separately)',
    checkoutTipsB: '- There is an additional charge for delivery up the stairs',
    checkoutTipsC: '- Customers who choose to pick up the goods by themselves must call 2417 3236 three days in advance to make an appointment (cash only)',
    keywords: 'Please input keywords',
    Exploremore: 'Explore more',
    Discovermore: 'Discover more',
    More: 'More',
    nomessage: 'No Content',
    Apply: 'Sign Up'
  },
  DeliveryAddress: {
    AddAddress: 'Add address',
    ChooseDeliveryAddress: 'Select delivery address',
    EditBtn: 'Edit',
    DeleteBtn: 'Delete',
    AddDeliveryAddress: 'New Address',
    PostalCode: 'PostalCode',
    Area: 'Area',
    Province: 'Province',
    District: 'District',
    Address: 'Address',
    DefaultAddress: 'Set as default delivery address?',
    SaveBtn: 'Save',
    ResetBtn: 'Reset',
    CloseBtn: 'Close',
    YesBtn: 'Yes',
    NoBtn: 'No',
    AddFirstName: 'First Name',
    AddLastName: 'Last Name',
    UserContactNumber: 'Phone',
    Mobile: 'Mobile',
    Addressprovince: 'Please choose the province',
    AddressArea: 'Please choose the area',
    Detail: 'detail'
  },
  Order: {
    OrderNumber: 'Order Number',
    OrderDateTime: 'Order Date & Time',
    TotalPrice: 'TotalPrice',
    OrderStatus: 'Order Status',
    Paid: 'Paid',
    Unpaid: 'Unpaid',
    PayNow: 'PayNow'
  },
  RegNPay: {
    BackText: 'Back',
    UploadButtonText: 'Upload files(jpg, png, pdf)',
    UploadingText: 'Uploading...',
    ConfirmText: 'OK',
    UploadSuccessfulText: 'Upload Successful',
    UploadFailText: 'Upload failed',
    NoFileText: 'No file selected',
    UploadLengthText: 'You can only upload {0} files at most',
    UploadSizeText: 'You can only upload {0} M at most',
    PleaseSelect: 'Please Select',
    SubRecord: 'Submission Record',
    ReferenceNum: 'Reference Number',
    SubmissionDate: 'Submission Date',
    Print: 'Print',
    PlsPayWay: 'Please select the mode of payment',
    PreviewTitleText: 'Please confirm your information again.',
    FormatErrorText: 'Please input correct email address.',
    RequiredText: 'Required'
  },
  Display: {
    SuccessCase: 'Successful Case',
    GeneralQuery: 'General Query',
    OfficeHours: 'Office Hours',
    Hour0: 'Monday to Friday：',
    Hour1: '9:00 a.m. to 12:00 p.m.,',
    Hour2: 'From 1:30 p.m. to 6 p.m',
    Hour3: 'Closed on Saturdays, Sundays and public holidays',
    AddrInfo: "Rm 306, 3 / f, tin's enterprise centre, 777 lai chi kok road, cheung sha wan (cheung sha wan MTR station exit B)"
  },
  Action: {
    ViewMore: 'MORE',
    SignUp: 'Sign Up',
    ReturnHomePage: 'Return Home Page',
    OrderDetails: 'Order Details',
    ViewDetail: 'VIEW DETAILS'
  },
  Cms: {
    PlsChoose: 'Please click to select the content you want to view :'
  },
  payment: {
    PaymentSuccessTips: 'Payment Result : Success',
    PaymentFailTips: 'Payment Result : Fail'
  },
  Calendar: {
    KnowMore: 'Know More',
    Time: 'Time',
    Venue: 'Venue',
    NoEvent: 'No activity today'
  },
  photo: {
    photo: 'photo',
    BackText: 'Back to previous'
  },
  pager: {
    Last: 'Last',
    Next: 'Next',
    per: 'page',
    page: '',
    Total: 'Total'
  },
  Home: {
    Home: 'Home',
    Search: 'Search Results',
    ReadMore: 'Read More',
    PersonalInformation: 'Personal Information',
    BecomeMember: 'Become Member',
    ContactUs: 'Contact Us'
  },
  Login: {
    doLogin: 'Login',
    LoginTitle: 'Login',
    NewUser: 'New buyer',
    RegNow: 'Register now',
    ForgetPwd: 'Forget Password',
    RememberMe: 'Remember Me',
    FaceBookUse: 'Login with FaceBook',
    UserName: 'Login Name/Email',
    EnterEmail: 'Please Enter The Email',
    CorrectEmail: 'Please Enter The Correct Email',
    LoginPwd: 'Please enter your password',
    LoginoldPwd: 'Incorrect password',
    Loginerror: 'Incorrect account or password',
    LoginSucceeded: 'Login Succeeded',
    Account: 'Account',
    MemberLogin: 'Member Login'
  },
  Register: {
    RegisterTitle: 'Registration',
    UserEmail: 'Email',
    or: 'or',
    UserRegPassword: 'Password',
    UserNewPassword: 'New Password',
    UserOldPassword: 'Old Password',
    UserRegTips: 'Min.6 characters with letters and numbers',
    UserConfirmPassword: 'Confirm',
    UserFirstName: 'First Name',
    UserLastName: 'Last Name',
    UserContactNumber: 'Phone',
    UserBirthDate: 'Birth Date',
    UserGender: 'Gender',
    UserMale: 'Male',
    UserFemale: 'Female',
    UserLanguage: 'Language',
    UserLanguageE: 'English',
    UserLanguageT: 'Traditional Chinese',
    UserLanguageS: 'Simplified Chinese',
    RegisterBtn: 'Register',
    RegisterAgree: 'I hereby confirm that the information provided herein is correct',
    RegisterEmail: 'Email cannot be empty',
    RegisterEmail01: 'Please enter a formal mailbox format',
    RegisterPassword: 'Please enter your password',
    RegisterNewPassword: 'Please enter your password again',
    RegisterNewPassword01: 'Two inconsistent input passwords',
    RegisterFirstName: 'Please enter your name',
    RegisterLastName: 'Name please enter your name',
    RegisterAgree_check: 'Please check',
    RegisterMobile: 'Please enter your phone',
    RegisterSuccess: 'Register Succeeded',
    RegisterFail: 'Register Failed',
    RegisterWelcome: 'Congratulations on your successful registration',
    RegisterTips: 'This email address has not been registered, register to become a member immediately!',
    Login: 'Login now!',
    Registerdigital: 'Length from 8 to 20 must contain uppercase letters, lowercase letters and numbers'
  },
  Notification: {
    MyMessages: 'My Messages',
    MsgList: 'Message List',
    TotalRecQtyWithSymbol: 'Total Qty. ：',
    UnreadWithSymbol: 'Unread Qty：',
    MsgUnreadOnly: 'Unread Message Only',
    MsgMarkAsRead: 'Mark as read',
    CheckAllOrNot: 'Check All/Not',
    MsgSender: 'Sender',
    MsgSubject: 'Subject',
    MsgSenderDate: 'Send Date',
    MsgReadStatus: 'Status',
    Details: 'Details',
    More: 'More',
    Back: 'Back',
    MsgContent: 'Content',
    Send: 'Send',
    Unread: 'Unread',
    Read: 'Read',
    UpdateSucceeded: 'Update Succeeded!',
    Check: 'Check',
    Id: 'Id'
  },
  Forgetpassword: {
    ForgetPassword: 'Forget Password',
    ResetPwdTips:
      'Please enter the registered email you used for registration, then click Next to continue',
    LoginNameEmail: 'Email',
    NextStep: 'Next',
    ResetPwdSuccess: 'Temp Password is sended to your email,please check.',
    NotEmail: 'Cannot receive the Activate Email',
    ToReEnter: 'Click here to re-enter',
    EnterEmail: 'Please Enter The Email',
    CorrectEmail: 'Please Enter The Correct Email',
    ResetPwd: 'Reset Password'
  },
  MemberInfo: {
    MemberInfoTitle: 'Member Info',
    UserAccount: 'User Account',
    ModifyPassword: 'Modify Password',
    EnterUserName: 'Please Enter Fristname',
    EnterUserLastName: 'Please Enter Lastname',
    EnterUserPhone: 'Please Enter Phone',
    EnterUserBrithDate: 'Please Select BrithDate',
    EnterOldPwd: 'Please Enter Old Password',
    EnterNewPwd: 'Please Enter New Password',
    EnterNumber: 'required number',
    EnterComfirmPwd: 'Please Enter Comfirm Password',
    PwdNotCorrect: 'The passwords not match twice',
    AgreeEmail: 'I agree that edtoy will send me promotional information through e-mail address',
    DDMM: 'DD/MM'
  },
  Input: {
    email: 'Please enter the correct email',
    number: 'Please enter a number',
    input: 'Please input ',
    text: '',
    date: 'Please enter a date',
    password: 'Two inconsistent input passwords',
    textarea: '',
    emailNotFound: 'Email Not Found',
    logopassword: 'Please input passwords',
    confirmpassword: 'Two inconsistent input passwords',
    nomessage: 'No Message',
    phoneincorrect: 'The phone number format is incorrect'
  },
  MessageNew: {
    Search: 'Search',
    Title: 'Title',
    Date: 'Date',
    More: 'More',
    President: "President's & Division Chairman's Messages",
    HIREA: 'GBA REDbird ENTREPRENURS ALLIANCE',
    Event: 'Event',
    News: 'News',
    Disclaimer: 'Disclaimer | Privacy Policy',
    CPD: 'CPD',
    ReadMore: 'Read More',
    NoContent: 'No Content',
    Year: 'Year',
    Month: 'Month',
    All: 'All Divisions',
    Hirea: 'GBA REDbird ENTREPRENURS ALLIANCE',
    selectYear: 'Please select the year',
    UpdateTime: 'Record updated until 1 June 2022',
    LearnMore: 'LEARN MORE'
  },
  Account: {
    MemberCenter: 'Member Center',
    MemberInformation: 'Member Information',
    MyMessages: 'My Messages',
    MyFavorite: 'My Favorite',
    MyOrder: 'My Order',
    DeliveryAddress: 'Delivery Address',
    Logout: 'Logout',
    Welcome: 'WELCOME!',
    SISA: 'Succeed in Choosing',
    Time: 'Time'
  },
  Month: {
    January: 'January',
    February: 'February',
    March: 'March',
    April: 'April',
    May: 'May',
    June: 'June',
    July: 'July',
    August: 'August',
    September: 'September',
    October: 'October',
    November: 'November',
    December: 'December'
  },
  MemberLogin: {
    MemberLogin: "Member's Login",
    MyPersonalInformation: 'My Personal Information ',
    MyCPDRecords: 'My CPD Records',
    MyApplications: 'My Applications & Payment Records',
    MyMailBox: 'My Mail Box',
    Update: 'Update',
    Back: 'Back',
    ModifyPassword: 'ModifyPassword'
  },
  MyPersonalInformation: {
    Welcome: 'Welcome',
    LastName: 'Last Name',
    FirstName: 'First Name',
    ChineseName: 'Chinese Name',
    ClassofMembership: 'Class of Membership',
    MembershipNumber: 'Membership Number',
    Divisions: 'Divisions',
    ContactAddress: 'Contact Address',
    CurrentCompanyNameandAddress: "Current Company's Name and Address",
    OfficeTelNo: 'Office Tel No.',
    CompanyEmail: 'Company E-mail',
    Fax: 'Fax',
    HomeAddress: 'Home Address',
    HomeTelNo: 'Home Tel No.',
    PersonalEmail: 'Personal E-mail',
    MobileNoHK: 'Mobile No. (HK)',
    MainContactNo: 'Main Contact No.',
    receiveInfo: 'I want to receive the information from Hong Kong Institute of Real Estate Administrators through the following channel:',
    Post: 'Post',
    Email: 'E-mail',
    SMSMessage: 'SMS Message'
  },
  MyCPDRecords: {
    UpcomingCPDActivities: 'Upcoming CPD Activities',
    CPDActivitiesRecord: 'CPD Activities Record',
    ActivityDate: 'Activity Date',
    ActivityTitle: 'Activity Title',
    Category: 'Category',
    Subject: 'Subject',
    ActivityMode: 'Activity Mode',
    CPDPointsAccredited: 'CPD Points Accredited',
    MediumofInstruction: 'Medium of Instruction',
    Register: 'Register'
  },
  MyApplicationsPaymentRecords: {
    MyApplicationsPaymentRecords: 'My Applications & Payment Records',
    Underreview: 'Project under review(Please submit your cheque within three days)',
    ActivityTitle: 'Activity Title',
    Referenceno: 'Reference no.',
    Date: 'Date',
    Total: 'Total (HK$)',
    Ereceipt: 'E-receipt',
    Membershipexpireson: 'Membership expires on',
    Membershiprenewal: 'Membership renewal',
    Status: 'Status'
  },
  MembersList: {
    MembersList: 'Members List ',
    SearchMember: 'Search Member',
    TipsA: 'Please enter the name to search in the boxes',
    TipsB: 'Please complete the following information',
    Submit: 'Submit',
    MembershipNumber: 'Membership Number',
    NameinEnglish: 'Name in English',
    NameinChinese: 'Name in Chinese',
    Professionaltitle: 'Professional Title',
    ClassofMembership: 'Class of Membership'
  },
  MembershipApplicationForm: {
    MembershipApplicationForm: 'Membership Application Form',
    ClassofMembershipApply: 'Class of Membership Apply',
    MembershipNumber: 'Membership Number',
    StudentMember: 'Student Member',
    AssociateMember: 'Associate Member(AHIREA)',
    GraduateMember: 'Graduate Member',
    ProfessionalMember: 'Professional Member(MHIREA)',
    Divisions: 'Divisions',
    MaychoosemorethanONEdivision: 'Please indicate which division(s) wish to join)',
    PropertyDevelopment: 'Property Development & Planning',
    LegalPractice: 'Legal Practice & Mediation',
    ConstructionDesign: 'Construction & Conservation',
    CorporateMgtFinance: 'Corporate Finance & Asset Mgt',
    PropertyManagement: 'Property & Facilities Mgt',
    SalesLeasing: 'Real Estate Sales Leasing & Marketing',
    RealEstateTechnology: 'Construction Technology & Innovation',
    Academic: 'Academic & Education',
    Important: 'All applicants should study carefully and observe the membership application procedures and rules before completion and submission of this form.',
    FamilyName: 'Family Name (Prof./Dr./Mr./ Ms.)',
    OtherNames: 'Other Names',
    NameinChinese: 'Name in Chinese',
    Nationality: 'Nationality',
    DateofBirth: 'Date of Birth(DD / MM / YYYY)',
    HKIDCard: 'HKID Card / Passport No.',
    CurrentAddress: "Current Company's Name and Address",
    OfficeTelNo: 'Office Tel No.',
    CompanyEmail: 'Company E-mail',
    HomeAddress: 'Home Address',
    HomeTelNo: 'Home Tel No.',
    PersonalEmail: 'Personal E-mail',
    MobileNo: 'Mobile phone number',
    MainContactNo: 'Other phone number',
    Usemailingaddress: 'Please use the * company / home address as the mailing address.',
    AcademicQualifications: 'Academic Qualifications',
    Pleaseprovidecopy: 'Please provide copy',
    Forapplying: 'For applying “Property Management Division” applicants need to fulfill the PMP (Tier 1) or (Tier 2) licence academic requirement of PMSA.',
    NameofUniversity: 'Name of University/Education Institution',
    QualificationAchieved: 'Qualification Achieved (Degree/Diploma)',
    Yearattended: 'Year attended',
    From: 'From (MM/YY)',
    To: 'To (MM/YY)',
    Remarks: 'Remarks',
    spaceisnotsufficient: 'If space is not sufficient, please use attached Supplementary Sheet A to supplement.',
    StatutoryProfessionalQualifications: 'Statutory Professional Qualifications',
    NameofProfessionalInstitution: 'Name of Professional Institution',
    LicenceGrade: 'Licence．Grade/Division of Membership',
    DateObtained: 'Date Obtained (DD/MM/YY)',
    PropertyManagementServicesAuthority: 'Property Management Services Authority',
    PMPTier1: 'PMP (Tier 1)',
    PMPTier2: 'PMP (Tier 2)',
    EstateAgentsAuthority: 'Estate Agents Authority',
    EstateAgent: 'Estate Agent (Ind.)',
    EstateSalesperson: 'Estate Salesperson',
    ArchitectsRegistrationBoard: 'Architects Registration Board',
    RegisteredArchitect: 'Registered Architect',
    EngineersRegistrationBoard: 'Engineers Registration Board',
    RegisteredProfessionalEngineer: 'Registered Professional Engineer',
    SurveyorsRegistrationBoard: 'Surveyors Registration Board',
    RegisteredProfessionalSurveyor: 'Registered Professional Surveyor',
    PlannersRegistrationBoard: 'Planners Registration Board',
    RegisteredProfessionalPlanner: 'Registered Professional Planner',
    LandscapeArchitectsRegistrationBoard: 'Landscape Architects Registration Board',
    RegisteredLandscapeArchitect: 'Registered Landscape Architect',
    HousingManagerRegistrationBoard: 'Housing Manager Registration Board',
    RegisteredProfessionalHousingManager: 'Registered Professional Housing Manager',
    OtherProfessionalQualifications: 'Other Professional Qualifications',
    CurrentEmploymentDetails: 'Current Employment Details',
    personalCV: 'Please provide a copy of detail personal CV ',
    CompanyNameCurrent: 'Company Name (Current)',
    CurrentPosition: 'Current Position',
    CurrentPositionSince: 'Current Position Since (MM/YY)',
    NatureofDuties: 'Nature of Duties (Current)',
    Pleasetick: '*Please tick in the box where appropriate',
    SizeofCompany: 'Size of Company',
    ListedCompany: 'Listed Company / More than 100 direct employees',
    CompanywithA: 'Company with 51-100 direct employees',
    CompanywithB: 'Company with 31-50 direct employees',
    CompanywithC: 'Company with 10-30 direct employees',
    CompanywithD: 'Company with less than 10 direct employees',
    FounderExecutiveDirector: 'Founder / Executive Director / Partner / Chairman',
    DirectorGeneralManager: 'Director / General Manager / Chief ExecutiveOfficer',
    SeniorManager: 'Senior Manager / Professional Manager',
    ManagerDeputyManager: 'Manager/ Deputy Manager / Asst. Manager',
    Others: 'Others',
    Pleaseshowyourposition: 'Please show your position in current employment by an Organization Chart together with number of direct supervised staff',
    UploadFile: 'Upload File',
    Currenttotalnumber: 'Current total number of sub-ordinates',
    ScopeofWork: 'Scope of Work',
    ScopeofWorkA: '% in Real Estate Administration',
    ScopeofWorkB: '% in Others',
    PastEmploymentHistory: 'Past Employment History',
    NameofCompany: 'Name of Company',
    Position: 'Position',
    Period: 'Period',
    NatureofDutiesA: 'Nature of Duties',
    SizeofCompanyA: 'Size of Company (Total No. of Staff)',
    SheetB: 'If space is not sufficient, please use attached Supplementary Sheet B to supplement.',
    CurrentandPrevious: 'Current and Previous Public Services',
    Particulars: 'Particulars',
    CriminalConvictionRecord: 'Criminal Conviction Record',
    CriminalConvictionRecordTipsA: 'Have you been convicted of any criminal offence in Hong Kong or elsewhere?',
    CriminalConvictionRecordTipsB: 'Have you been sentenced to imprisonment (including suspended sentence)?',
    CriminalConvictionRecordTipsC: 'Have you confronted to any disciplinary actions (including Professional Bodies, EAA & PMSA)?',
    Yes: 'Yes',
    No: 'No',
    CriminalConvictionRecordRemark: 'If your answer for the above question is “yes”, please provide further information or explanation on a separate sheet which should include date of conviction, name of the offence, the punishment (if any) and the court which tried the offence.',
    I: 'I',
    wishtojoinA: 'wish to join the "Hong Kong Institute of Real Estate Administrators" as a member. I understand that my personal information ',
    wishtojoinB: ' filled out in my application form will be kept in confidence by the Institute and the same may be provided to the members of the General Council for the purpose of processing of my application for membership. I hereby agree to your access to my personal data and understand there is no breach of the PERSONAL DATA (PRIVACY) ORDINANCE on your part as a result of your so doing as aforesaid. I hereby declare that the particulars on this form are TRUE AND CORRECT and that if elected to membership agree to abide by the rules and by-laws of the Institute and by any amendments which may thereto be made at any time. I further agree that this application form will be retained by the Institute for records until my written notification of withdrawal.',
    Iencloseherewith: 'I enclose herewith a cheque no.',
    HK$: ' of HK$',
    beingtheassessmentA: ' being the assessment fee (HK$250)  and first annual',
    beingtheassessmentB: ' subscription, on the understanding that it will be returned to me except the assessment fee in the event of non-election.',
    SignatureofApplicant: 'Signature of Applicant',
    Date: 'Date',
    Proposer: 'Proposer',
    MustbeaFellow: 'Must be a Fellow Member or a Professional Member',
    NameofProposer: 'Name of Proposer (In Full)',
    ClassofMembership: 'Class of Membership',
    MembershipNo: 'Membership No.',
    Signature: 'Signature',
    FOROFFICEUSEONLY: 'FOR OFFICE USE ONLY',
    ApplicationReceivedon: 'Application Received on',
    Acknowledgmentsent: 'Acknowledgment sent to applicant',
    Applicationvetted: 'Application vetted by Membership Committee',
    ApprovalRejection: '* Approval / Rejection by General Council as : Professional Member / MHIREA (PMD) / Associate Member / AHIREA (PMD) / Graduate Member / Student Member / Non-Resident Member',
    ApplicationResult: 'Application Result Notice to Applicant',
    NotesforMembershipApplication: 'Notes for Membership Application',
    ClassesofMembership: 'Classes of Membership and Basic Entrance Requirements',
    TheInstitutehasstrictrequirements: 'The Institute has strict requirements for admission of members so as to conform to its Memorandum and Articles and also to fulfill Government’s requirements. To become members of Hong Kong Institute of Real Estate Administrators, the applicant are generally required to satisfy the requirements as stated below table in the respective class of membership. Exemption on some of these requirements may be granted to applicants in the appropriate categories.',
    BasicRequirements: 'Basic Requirements',
    HonoraryFellow: 'Honorary Fellow [ Hon. FHIREA ]',
    FellowMember: 'Fellow Member [ FHIREA ]',
    ProfessionalMemberMHIREA: 'Professional Member [ MHIREA ]',
    ProfessionalMemberPropertyManagementDivision: 'Professional Member (Property & Facilities Mgt. Division) [ MHIREA ]',
    AssociateMemberAHIREA: 'Associate Member [ AHIREA ]',
    AssociateMemberPropertyManagementDivision: 'Associate Member (Property & Facilities Mgt. Division) [ AHIREA ]',
    NonResidentMember: 'Non-Resident Member[ FHIREA (Non-Resident) /MHIREA (Non-Resident) ]',
    SeniorMemberFHIREA: 'Senior Member[ FHIREA / MHIREA ]',
    BasicRequirementsTipsA: 'Any distinction or high social standing person who has rendered outstanding service contribution to the Institute and/or the real estate filed to be nominated by the General Council',
    BasicRequirementsTipsB1: 'To be nominated by the General Council, and',
    BasicRequirementsTipsB2: 'A holder of Hong Kong Permanent Identity Card',
    BasicRequirementsTipsC1: 'Any administrator or executive, over the age of 25 years, holding real estate or related an accredited degree or diplomas (HKQF Level 5 or above) recognized by the Institute or approved by the General Council, or has passed the examinations of the Institute, with at least 5 years managerial experience in the real estate industry; or',
    BasicRequirementsTipsC2: 'Members of other professional institutes recognized by the Institute with over 2 years post professional qualification relevant managerial experience; or',
    BasicRequirementsTipsC3: 'Non-degree holders with over 10 years’ supervisory or managerial experience.',
    BasicRequirementsTipsC4: 'A professional member must be a holder of Hong Kong Permanent Identity Card.',
    BasicRequirementsTipsC5: 'Same basic requirements as “Professional Member” and meeting the requirements of academic and working experience for PMP (Tier 1) licence required by “Property Management Services Authority (PMSA)”.',
    BasicRequirementsTipsD1: 'Any person, over the age of 25 years, holding a degree as required of the Professional Member and with at least 3 years supervisory or managerial experience in the real estate industry, or',
    BasicRequirementsTipsD2: 'Members of other professional institutes accredited by the Institute with relevant experience or managerial experience, or',
    BasicRequirementsTipsD3: 'Non-degree holders with over 6 years’ supervisory or managerial experience.',
    BasicRequirementsTipsD4: 'Same basic requirements as “Associate Member” and meeting the requirements of academic, working experience and CPD for PMP (Tier 1) licence or PMP (Tier 2) licence required by “Property Management Services Authority (PMSA)”.',
    BasicRequirementsTipsE: 'Being a graduate who has successfully completed the related course as required for Student Member by the Institute; or any Real Estate Administration related course(s) recognized or approved by the General Council.',
    BasicRequirementsTipsF1: 'Being a student of any Real Estate Administration course(s) recognized or approved by the General Council.',
    BasicRequirementsTipsF2: 'The General Council can extend the student membership beyond the duration of the course in its discretion.',
    BasicRequirementsTipsG1: 'Non-Resident Member Fellow and Professional Member ordinarily residing overseas ; or',
    BasicRequirementsTipsG2: 'Any person having the professional experience and qualification as the Professional Member, but not holding a permanent Hong Kong Identity Card and being approved by the Institute to be of Non-Resident Status.',
    BasicRequirementsTipsH: 'A member, who has attained the age of 65 and has had at least 10 years membership with the Institute before transfer to the status of Senior Member with the approval of the Institute.',
    MembershipApplicationProcedures: 'Membership Application Procedures and Rules',
    Anylocalpractitioners: 'Any local practitioners in real estate administration, who satisfy our basic requirements for Professional members or other class of membership (including Associate membership) and have good reputation and character, are welcome to join the Institute. Any persons who wish to join the Institute as members may apply for membership through the following procedure:',
    MTA: 'Complete the application form (can be download through the Institute official website http://www.hirea.org.hk) in detail, sponsored by one Fellow or Professional member as proposer and forward the completed form to the Chairman of the Membership Committee via mail. The mail address of the Institute is P.O. Box No.4126, GPO, Hong Kong.',
    MTB: 'The following documents must be submitted together with the application form :',
    MTB1: 'Photo copy of the applicant’s HKID or Passport.',
    MTB2: 'One 2” x 1.5” color photo (Please write down the name of the applicant at the back and do not submit any instant photograph).',
    MTB3: 'Certificates (xeroxed copy acceptable) of academic qualification(s), professional qualification(s) and employment or practice record(s).',
    MTB4: 'Detail employment or practicing records and the last/current Name Card.',
    MTB5: 'Record of Continuous Professional Development (if any)',
    MTB6: 'A crossed cheque made payable to “Hong Kong Institute of Real Estate Administrators” in the amount of  assessment fee (HK$250) plus one year’s membership fee for the class of the membership applied by the applicant. (Note: If the application is accepted, the cheque will be cleared for payment of assessment fee of HK$250 and the annual membership fee. Only the annual membership fee will be refunded to the applicant if the application is rejected. Rates of annual membership fee are different according to the class of membership and are adjustable annually. Please refer to the Web Site of the Institute for the current rate of annual membership fee.)',
    MTC: 'All applications will be considered in detail by the Membership Committee of the General Council. If enquired, members of the Membership Committee may directly contact the applicant to clarify any doubts or for further details.',
    MTD: 'After scrutiny, the Membership Committee will forward the application to the General Council for consideration and voting. An application can only be accepted through an unanimously voting for acceptance by the General Council Members present in a validly held meeting. The Chairman of the Membership Committee will notify the applicant of the result of his application directly through mail.',
    MTF: 'The Institute reserves its right to accept or refuse any application for membership and is not obliged to provide any explanation in any case. All applicants shall observe and respect the final decision of the General Council absolutely.',
    MTG: 'For enquiries, please contact the Institute Secretariat.',
    RemoveRow: 'Remove Row',
    AddRow: 'Add Row',
    Submit: 'Submit',
    Sign: 'Sign',
    Save: 'Save',
    Clear: 'Clear',
    Preview: 'Preview',
    Modify: 'Modify',
    exceed: 'Exceeded quantity ',
    exceedText: 'Please delete the original file first, and then select the file one more time ,since the restriction is one file.',
    MaxSize: 'Max File Size',
    Secretariat: 'Secretariat',
    TextMessageOnly: 'Text Message Only',
    Tel: 'Tel',
    TelContent: '3588 1799    9144 2633',
    Fax: 'Fax',
    FaxContent: '3007 0675    2280 6582',
    Address: 'Address',
    AddressContent: 'P.O. Box No.4126, GPO, Hong Kong',
    Email: 'Email',
    EmailCotent: 'info@hirea.org.hk ',
    Website: 'Website',
    WebsiteContent: 'https://cmx4-redbird.uat2.intimex.hk ',
    ParticularofEntrepreneur: 'Particulars of Applicant',
    EntrepreneurName: 'Name of Applicant',
    Surname: 'Surname',
    FirstName: 'First name',
    HKUSTS: 'Relationship with HKUST',
    multiple: '(can be multiple)',
    Department: 'Department',
    affiliation: 'Year in which the relationship began',
    Upload: 'Upload any proof of the relationship',
    ContactMeans: 'Contact means',
    EmailAddress: 'Email address',
    WeChatID: 'WeChat ID (if any)',
    WhatSapp: 'WhatsApp number (if any)',
    Post: 'Title in the Company',
    Particular: 'Particulars of Company owned by The Entrepreneur',
    CompanyName: 'Name of Company',
    EnglishName: 'English',
    ChineseName: 'Chinese (if any)',
    CompanyAddress: 'Company address',
    CompanyWeb: 'Company website',
    NatureBusiness: 'Nature of Business (can be multiple)',
    CompanyBackground: 'Company background',
    BusinessActivities: 'Business Activities',
    Collection: 'Personal Information Collection Statement',
    Limited: "Personal data collected in this form will be used for the Alliance's membership services, including but not limited to:",
    Applyingfor: 'Applying for',
    Membership: 'Membership (entrepreneur)',
    OrAffiliateship: 'Or Affiliateship (company)',
    PlaceofIncorporation: 'Place of Incorporation',
    Companynumber: 'Company number:'
  },
  BoAForm: {
    MaxSize: 'Max File Size',
    Required: 'This item is required',
    Privacy: 'Privacy Policy',
    Terms: 'Terms & Conditions',
    Disclaimer: 'Disclaimer',
    Last: 'Last',
    Next: 'Next',
    SelectDate: 'Select Date',
    SubmitSuccess: 'Submitted Successfully',
    SubmitFail: 'Submission Failed',
    CorrectEmail: 'Please enter the correct email',
    CorrectPhone: 'Please enter the correct phone',
    Code: 'Code',
    Identity: 'Identity',
    ProjectSection: 'Project Section',
    ProjectOwner: 'Project Owner',
    exceed: 'Exceeded quantity ',
    exceedText: 'Please delete the original file first, and then select the file one more time ,since the restriction is one file.',
    RegSuccessTipsA: 'Your membership account activation is currently being reviewed',
    RegSuccessTipsB: 'Please check your email for further instructions',
    ApplySuccess: 'Your application was submitted successfully; a confirmation email will be sent to your registered email box once your application has been approved by the administrator.',
    Savedsuccessfully: 'Saved successfully',
    Expired: 'Expired',
    selectProposers: 'Select Proposers'
  }
};
